import { APP_ENABLE_DEV_CONFIG } from '@common/constants/localStorageKeys';
import { reactLocalStorage } from 'reactjs-localstorage';
import devConfig from './dev.config';
import prodConfig from './prod.config';

const config = function () {
  // const stored = reactLocalStorage.get(APP_ENABLE_DEV_CONFIG);
  // const enableDevConfig = stored === 'true';
  // if (enableDevConfig) {
    return devConfig;
  // }
  //
  // return prodConfig;
}();

export default config;
