const cdnFormula = 'https://formula55-cloudfront-content-dev.s3-eu-west-1.amazonaws.com';
const cdnInbet = cdnFormula;
const cdnSmartsoftGaming = cdnFormula;
const config = {
  cdn: cdnFormula,
  host: 'https://api.dev.55winers.com',
  eventSearch: 'https://events-search.dev.55winers.com',
  socket: 'wss://ws-live.dev.55winers.com/ws',
  stream: 'https://5e993dcc4cda2.streamlock.net:8443',
  streamRu: 'https://st6.mediabay.tv',
  gpsTracker: 'https://gps-tracker.dev.55winers.com',
  billingHost: 'https://billing.dev.55winers.com',
  inbet: 'li8eiBai',
  championUrl: 'https://championcasino.net',
  maintenance: false,
  isNewYearMode: true,
  newYear: false,
  newYearDate: 'Jan 1, 2025 12:00:00',
  inbetHost: 'https://devslot55winers.vlobby.co',
  googleAnalyticsIDs: {
    ua: {
      url: 'm.epicboom.com',
      id: 'G-8L1D14VMYB',
    },
    tj: {
      url: 'm.formula55.tj',
      id: 'G-6VTBE0L6ZZ',
    },
    uz: {
      url: 'm.f55uz.net',
      id: 'G-GT04YCMPW6',
    },
    ru: {
      url: 'm.f-55.ru',
      id: 'G-QK1MEH0QD1',
    },
    org: {
      url: 'm.formula55.org',
      id: 'G-T8BZPCXFZ1',
    },
    net: {
      url: 'm.formula55.net',
      id: 'G-1W0E4JDNRE',
    },
  },
  yandexAnalyticsIDs: {
    tj: {
      url: 'm.formula55.tj',
      id: '95949096',
    },
  },
  inbetHosts: {
    'm.development55.tj': true,
    'm.55ivebet.net': true,
    'm.55ivebet.com': true,
    'http://192.168.100.7:5555/': true,
    'localhost:5555': true,
    '192.168.100.7': true,
  },
  inbetBetPerLine: {
    TJS: [ 1, 2, 5, 10, 50 ],
    RUB: [ 5, 10, 20, 50, 100 ],
    UZS: [ 1000, 2000, 5000, 10000, 50000 ],
    USD: [ 1, 2, 5, 10, 50 ],
    EUR: [ 1, 2, 5, 10, 50 ],
    UAH: [ 1, 2, 5, 10, 50 ],
    KGS: [ 5, 10, 20, 50, 100 ],
  } as { [key: string]: number[] },
  sgames: {
    src: 'https://hub-web.rupr.upsl-tech.ru', //https://sportgames-hub-web-preprod.scorum.work
    cid: 'formula55tj',
    productId: 'sport-games-lobby',
  },
  domains: {
    ua: [
      'm.epicboom.com',
      'm.epicboomdev.com',
    ],
    ru: [
      'm.formula55.ru',
      'm.55ivebet.net',
    ],
    tj: [
      'localhost',
      'm.formula55.tj',
      'cloudfront-mobile.dev.55winers.com',
      'mobile-f55.dev.55winers.com',
      'm.development55.tj',
    ],
    uz: [
      'm.formula55uz.tj',
      'm.55betwin.com',
      'm.55bettime.com',
      'm.55ivebet.com',
      'm.sportbet.com',
      'm.sporting.com',
      'm.5ivebet.com',
      'm.formula55.net',
    ],
    kg: [
      'm.formula55.kg',
      'm.55sportbet.net',
    ],
    boxbet: [
      'm.boxbet.org',
      'm.devboxbet.org',
    ],
    org: [
      'm.formula55.org'
    ],
    net: [
      'm.formula55.net'
    ]
  },
  gpsAvailableCountries: [
    {
      'id': 119,
      'title': 'Kyrgyzstan',
      'code': 'KG',
    },
    {
      'id': 181,
      'title': 'Russian Federation',
      'code': 'RU',
    },
    {
      'id': 213,
      'title': 'Tajikistan',
      'code': 'TJ',
    },
    {
      'id': 227,
      'title': 'Ukraine',
      'code': 'UA',
    },
    {
      'id': 233,
      'title': 'Uzbekistan',
      'code': 'UZ',
    },
  ],
  inbetList: [
    {
      class: 1,
      title: 'KENO LIVE',
      url: 'bet_keno',
      img: `${cdnInbet}/m_inbet/bet_keno.jpeg`,
    }, {
      class: 1,
      title: 'KENO NEON',
      url: 'bet_kenoneon',
      img: `${cdnInbet}/m_inbet/bet_kenoneon.jpeg`,
    }, {
      class: 1,
      title: 'KENO NIGHT',
      url: 'bet_kenonight',
      img: `${cdnInbet}/m_inbet/bet_kenonight.jpeg`,
    }, {
      class: 1,
      title: 'XKENO',
      url: 'bet_xkeno',
      img: `${cdnInbet}/m_inbet/bet_xkeno.jpeg`,
    }, {
      class: 1,
      title: 'PENALTY',
      url: 'bet_penalty',
      img: `${cdnInbet}/m_inbet/bet_penalty.jpeg`,
    }, {
      class: 1,
      title: 'VIRTUAL SOCCER',
      url: 'bet_vsoccer',
      img: `${cdnInbet}/m_inbet/bet_vsoccer.jpeg`,
    }, {
      class: 1,
      title: 'KENO DRAW',
      url: 'e_keno_draw',
      img: `${cdnInbet}/m_inbet/e_keno_draw.jpeg`,
    }, {
      class: 1,
      title: 'KENO DRAW 2',
      url: 'e_keno_draw2',
      img: `${cdnInbet}/m_inbet/e_keno_draw2.jpeg`,
    },
    {
      class: 2,
      img: `${cdnSmartsoftGaming}/ssgames/category/keno/classic_keno.jpeg`,
      name: 'ClassicKeno',
      title: 'CLASSIC KENO',
      category: 'Keno',
    },
    {
      class: 2,
      img: `${cdnSmartsoftGaming}/ssgames/category/keno/russian_keno.jpeg`,
      name: 'RussianKeno',
      title: 'RUSSIAN KENO',
      category: 'Keno',
    },
  ],
  admiral: {
    host: 'https://admiral-casino.dev.55winers.com',
  },
  iosShowKeno: false,
  hideCasino: false,
  iosVersion: '1.2.9',
  feedback: {
    tj: {
      messengers: [
        {
          title: 'WhatsApp',
          url: 'https://wa.me/992940435555',
          icon: 'whatsapp',
          username: '992940435555',
        },
        {
          title: 'Viber',
          url: 'viber://chat?number=%2B992940435555',
          icon: 'viber',
          username: '992940435555',
        },
        {
          title: 'Telegram',
          url: 'tg://resolve?domain=F55_tj',
          icon: 'telegram',
          username: 'f55_tj',
        },
      ],
      socials: [
        {
          icon: 'facebook',
          url: 'https://www.facebook.com/pg/F55Tajikistan/posts/?ref=page_internal',
          title: 'Facebook',
        },
        {
          icon: 'instagram',
          url: 'https://www.instagram.com/bkformula55/',
          title: 'Instagram',
        },
        {
          icon: 'odnoklassniki',
          url: 'https://ok.ru/formula55tj',
          title: 'Odnoklassniki',
        },
        {
          icon: 'vk',
          url: 'https://vk.com/formula55',
          title: 'Vkontakte',
        },
      ],
      phones: [
        '(+992) 37 221 5510',
        '(+992) 37 884 5555',
        '5500',
      ],
      emails: [
        'admin@formula55.tj',
      ],
      support: [],
    },
    ru: {
      messengers: [
        {
          title: 'WhatsApp',
          url: 'tg://resolve?domain=f55_ru',
          icon: 'whatsapp',
          username: 'f55_ru',
        },
        {
          title: 'Viber',
          url: 'viber://chat?number=%2B992980265555',
          icon: 'viber',
          username: '992980265555',
        },
        {
          title: 'Telegram',
          url: 'tg://resolve?domain=f55_ru',
          icon: 'telegram',
          username: 'f55_ru',
        },
      ],
      socials: [
        {
          icon: 'facebook',
          url: 'https://fb.com/f55ru',
          title: 'Facebook',
        },
        {
          icon: 'instagram',
          url: 'https://www.instagram.com/formula55.ru/',
          title: 'Instagram',
        },
        {
          icon: 'odnoklassniki',
          url: 'https://m.ok.ru/formula55ru?__dp=y',
          title: 'Odnoklassniki',
        },
        {
          icon: 'vk',
          url: 'https://vk.com/formula55rus',
          title: 'Vkontakte',
        },
      ],
      phones: [
        '(+7) 495 902 50 55',
      ],
      emails: [],
      support: [],
    },
    uz: {
      messengers: [],
      socials: [],
      phones: [],
      emails: [],
      support: [
        {
          title: 'Telegram',
          url: 'tg://resolve?domain=boxbetuz',
          icon: 'telegram',
          username: 'boxbetuz',
        },
      ],
    },
    ua: {
      messengers: [
        {
          title: 'Messenger',
          url: 'https://m.me/216305621573784?ref=ZGw6MTk1MjI0',
          icon: 'messenger',
          username: 'epicboomsupport',
        },
        {
          title: 'Telegram',
          url: 'https://t.me/EpicboomSupp_bot?start=ZGw6MTk1MjI0',
          icon: 'telegram',
          username: 'Epicboom_Support_bot',
        },
      ],
      socials: [],
      phones: [],
      emails: [],
      support: [],
    },
  },
  languages: [
    {
      value: 'ru',
      label: 'Русский',
    },
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'tj',
      label: 'Тоҷикӣ',
    },
    {
      value: 'uz',
      label: 'O\'zbekcha',
    },
    {
      value: 'ua',
      label: 'Українська',
    },
    {
      value: 'kg',
      label: 'Кыргызча',
    },
  ],
  minBetAmount: 5,
  channelListRu: {
    1: { title: 1, name: 'FootballHD' },
    2: { title: 2, name: 'VarzishHD' },
    3: { title: 3, name: 'MatchHD' },
    4: { title: 4, name: 'MatchFutbol1' },
    5: { title: 5, name: 'MatchFutbol2' },
    6: { title: 6, name: 'MatchFutbol3' },
    7: { title: 7, name: 'MatchPremier' },
  } as Record<number, { title: number, name: string }>,
  eyeLiveSport: {
    api: 'https://eye-sports.dev.55winers.com',
    host: 'https://ui-stage.betsy.software',
    cid: 'formula55-stage',
    parent: 'https://yourdomain.com/',
    allowParentUrlUpdate: false,
  },
  // Smartsoft Gaming Config
  ssgHost: 'https://eu-staging.ssgportal.com',
  ssgGames: [
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/balloon.jpeg`,
      name: 'Balloon',
      title: 'BALLOON',
      category: 'Games',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/cappadocia.jpeg`,
      name: 'Cappadocia',
      title: 'CAPPADOCIA',
      category: 'Games',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/cricketx.jpeg`,
      name: 'CricketX',
      title: 'CRICKET X',
      category: 'XGames',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/plinkox.jpeg`,
      name: 'PlinkoX',
      title: 'PLINKO X',
      category: 'Games',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/spinx.jpeg`,
      name: 'SpinX',
      title: 'SPIN X',
      category: 'XGames',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/xgames/jetx3.jpeg`,
      name: 'JetX3',
      title: 'JETX3',
      category: 'XGames',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/burning_ice_10.jpeg`,
      name: 'BlazingHot10',
      title: 'BURNING ICE 10',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/burning_ice_40.jpeg`,
      name: 'BlazingHot40',
      title: 'BURNING ICE 40',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/kingdom_elves.jpeg`,
      name: 'Elves',
      title: 'THE KINGDOM OF ELVES',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/magic_garden_fruid.jpeg`,
      name: 'Fruit5',
      title: 'MAGIC GARDEN',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/multi_hot_5.jpg`,
      name: 'MultiHot5',
      title: 'MULTIHOT5',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/slots/zombies.jpeg`,
      name: 'Zombies',
      title: 'ZOMBIES',
      category: 'Slots',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/jetx/JetX_Page1-m.png`,
      name: 'jetX',
      title: 'JetX',
      category: 'jetX',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/keno/classic_keno.jpeg`,
      name: 'ClassicKeno',
      title: 'CLASSIC KENO',
      category: 'Keno',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/keno/russian_keno.jpeg`,
      name: 'RussianKeno',
      title: 'RUSSIAN KENO',
      category: 'Keno',
    },
    {
      img: `${cdnSmartsoftGaming}/ssgames/category/keno/vip_keno.jpeg`,
      name: 'VipKeno',
      title: 'VIP KENO',
      category: 'Keno',
    },
  ],
  ssgSliderSlides: [
    `${cdnSmartsoftGaming}/ssgames/swiper/Jetx-swiper-1-m.png`,
    `${cdnSmartsoftGaming}/ssgames/swiper/XGames-swiper-2-m.png`,
    `${cdnSmartsoftGaming}/ssgames/swiper/Slots-swiper-3-m.png`,
    `${cdnSmartsoftGaming}/ssgames/swiper/Casino-swiper-4-m.jpg`,
    `${cdnSmartsoftGaming}/ssgames/swiper/Keno-swiper-5-m.png`,
  ],
  sportradar: {
    api: 'https://sportradar.dev.55winers.com',
    iframe: {
      '/table-tennis': 'https://f55-dev.bets.osai.ai/#/bet',
      '/online-football': 'https://widget-esoccer-formula55.quickbets-nonprod.sportradar.dev/#/bet',
      '/table-tennis-squares': 'https://widget-squares-formula55.quickbets-nonprod.sportradar.dev/#/bet?eventId=502662',
    },
  },
  appLinks: {
    ua: [
      'https://cdn.epicboom.com/apps/epicboom.apk',
      'https://apps.apple.com/us/app/epicboom/id1575772186',
    ],
    ru: [
      'https://cdn.f-55.ru/apps/Formula55AndroidRu.apk',
      'https://apps.apple.com/tj/app/formula55/id1502992542',
    ],
    tj: [
      'https://cdn.formula55.tj/apps/Formula55Android.apk',
      'https://apps.apple.com/tj/app/formula55/id1502992542',
    ],
    uz: [
      'https://cdn.formula55.net/apps/Formula55AndroidUz.apk',
      'https://apps.apple.com/tj/app/formula55/id1502992542',
    ],
    kg: [
      'https://cdn.formula55.kg/apps/Formula55AndroidKg.apk',
      'https://apps.apple.com/tj/app/formula55/id1502992542',
    ],
  } as Record<string, [ string, string ]>,
  itunesLinks: {
    ua: 'itms-apps://itunes.apple.com/app/epicboom/id1575772186',
    ru: 'itms-apps://itunes.apple.com/app/formula55/id1502992542',
    tj: 'itms-apps://itunes.apple.com/app/formula55/id1502992542',
    uz: 'itms-apps://itunes.apple.com/app/formula55/id1502992542',
    kg: 'itms-apps://itunes.apple.com/app/formula55/id1502992542',
  } as Record<string, string>,
};

export default config;
