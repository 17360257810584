import BackdropProvider from '@common/components/backdrop/BackdropProvider';
import DialogConfirmProvider from '@common/components/dialog-confirm/DialogConfirmProvider';
import PopupAlertProvider from '@common/components/PopupAlert';
import SidebarProvider from '@common/components/Sidebar/SidebarProvider';
import SplashScreen from '@common/components/SplashScreen';
import { useAppSelector } from '@common/hooks/store';
import config from '@config';
import AppCampaignsLoader from '@features/app/AppCampaignsLoader';
import AppRoutes from '@features/app/AppRoutes';
import { selectAppLocale, selectAppTooltips } from '@features/app/slice/appSlice';
import UserGeolocation from '@features/auth/UserGeolocation';
import PersonalPopupProvider from '@features/banners/personal-popup/PersonalPopupProvider';
import Maintenance from '@features/maintenance/Maintenance';
import NewYear from '@features/new-year/NewYear';
import {
  useFetchLineExtraOddsTemplatesQuery,
  useFetchLineOddsAbbrsTemplatesQuery,
  useFetchLiveExtraOddsTemplatesQuery,
  useFetchLiveOddsAbbrsTemplatesQuery,
} from '@features/odds-templates/api/oddsTemplatesApi';
import { useFetchTranslationsQuery } from '@features/translations/api/translationsApi';
import TranslationsProvider from '@features/translations/TranslationsProvider';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';

export default function AppLoader() {
  const locale = useAppSelector(selectAppLocale);
  const tooltips = useAppSelector(selectAppTooltips);
  const [ isNewYear, setIsNewYear ] = useState<boolean>(config.newYear);

  const translatesQuery = useFetchTranslationsQuery({ locale, version: tooltips.vT });
  const lineOddsAbbrsTemplatesQuery = useFetchLineOddsAbbrsTemplatesQuery({ locale, version: tooltips.vPa });
  const lineExtraOddsTemplatesQuery = useFetchLineExtraOddsTemplatesQuery({ locale, version: tooltips.vP });
  const liveOddsAbbrsTemplatesQuery = useFetchLiveOddsAbbrsTemplatesQuery({ locale, version: tooltips.vLa });
  const liveExtraOddsTemplatesQuery = useFetchLiveExtraOddsTemplatesQuery({ locale, version: tooltips.vL });

  if (
    translatesQuery.isLoading ||
    lineOddsAbbrsTemplatesQuery.isLoading ||
    lineExtraOddsTemplatesQuery.isLoading ||
    liveOddsAbbrsTemplatesQuery.isLoading ||
    liveExtraOddsTemplatesQuery.isLoading
  ) {
    return <SplashScreen/>;
  }
  return (
    <TranslationsProvider>
      {isNewYear ?
        <NewYear setIsNewYear={setIsNewYear}/> :
        config.maintenance ? (
          <Maintenance/>
        ) : (
          <UserGeolocation>
            <AppCampaignsLoader>
              <SnackbarProvider
                variant="success"
                autoHideDuration={2500}
                maxSnack={1}
                dense={true}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                preventDuplicate
              >
                <BackdropProvider>
                  <DialogConfirmProvider>
                    <PopupAlertProvider>
                        <PersonalPopupProvider>
                          <SidebarProvider>
                            <AppRoutes/>
                          </SidebarProvider>
                        </PersonalPopupProvider>
                    </PopupAlertProvider>
                  </DialogConfirmProvider>
                </BackdropProvider>
              </SnackbarProvider>
            </AppCampaignsLoader>
          </UserGeolocation>
        )}
    </TranslationsProvider>
  );
}
