import { commonApi } from '@common/utils/baseQuery';
import config from '@config';
import { ICampaignParticipants, ICampaigns } from '@features/campaigns/api/campaignsApi.type';
import { getUnixTime } from 'date-fns';

export const campaignsApi = commonApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCampaigns: build.query<ICampaigns, void>({
      query: () => '/campaigns',
    }),
    fetchCampaignById: build.query<void, { id: number, lang?: string, refill?: boolean }>({
      query: ({ id, lang = 'ru', refill = false }) => ({
        url: `${config.cdn}/${refill ? 'campaignsRefill' : 'campaigns'}/${lang}/${id}.html`,
        params: {
          v: getUnixTime(Date.now()),
        },
        responseHandler: (response) => response.text(),
      }),
      extraOptions: { useToken: false },
    }),
    fetchCampaignParticipants: build.query<ICampaignParticipants, { online?: boolean }>({
      query: (params) => `/campaigns/top${params?.online ? '/online' : ''}`,
    }),
    leadCampaigns: build.mutation<void, string>({
      query: (code) => ({
        url: '/leadcampaigns',
        body: { code },
        method: 'POST',
      }),
    }),
    fetchCampaignsRefill: build.query<ICampaigns, void>({
      query: () => '/campaigns/online',
    }),
  }),
});

export const {
  useFetchCampaignsQuery,
  useFetchCampaignByIdQuery,
  useFetchCampaignParticipantsQuery,
  useFetchCampaignsRefillQuery,
} = campaignsApi;
