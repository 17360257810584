import { isDomainZone } from '@common/utils/lang';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import Countdown from 'react-countdown';
import { FormattedMessage } from 'react-intl';

interface IRendererProps {
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const renderer = ({ hours, minutes, seconds }: IRendererProps) => {
  const epicboomGradient = 'linear-gradient(232deg, #E6751A -13.61%, #F1C19B 44.1%, #FFF 95.45%)';
  const f55Gradient = 'linear-gradient(232deg, #ceae04 -13.61%, #d88b28 44.1%, #85430d 95.45%)';
  const boxbetGradient = 'linear-gradient(232deg, #0563F0 0.93%, #9ABCEE 46.37%, #FFF 95.45%)';

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center" gap="1em" textAlign="center">
        <Box sx={{
          background: isDomainZone('ua') ? epicboomGradient : isDomainZone('boxbet') ? boxbetGradient : f55Gradient,
        }} color="black" width="5em" paddingY={1} borderRadius="0.4em">
          <Typography fontWeight={700} fontSize="0.8em">
            <FormattedMessage id="HOURS"/>
          </Typography>
          <Typography fontSize="1.8em" fontWeight={700}>
            {hours}
          </Typography>
        </Box>
        <Typography fontSize="1.8em" fontWeight={700}>
          {':'}
        </Typography>
        <Box sx={{
          background: isDomainZone('ua') ? epicboomGradient : isDomainZone('boxbet') ? boxbetGradient : f55Gradient,
        }} color="black" width="5em" paddingY={1} borderRadius="0.4em">
          <Typography fontWeight={700} fontSize="0.8em">
            <FormattedMessage id="MINUTES"/>
          </Typography>
          <Typography fontSize="1.8em" fontWeight={700}>
            {minutes}
          </Typography>
        </Box>
        <Typography fontSize="1.8em" fontWeight={700}>
          {':'}
        </Typography>
        <Box sx={{
          background: isDomainZone('ua') ? epicboomGradient : isDomainZone('boxbet') ? boxbetGradient : f55Gradient,
        }} color="black" width="5em" paddingY={1} borderRadius="0.4em">
          <Typography fontWeight={700} fontSize="0.8em">
            <FormattedMessage id="SECONDS"/>
          </Typography>
          <Typography fontSize="1.8em" fontWeight={700}>
            {seconds}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface IExactScoreCountDownProps {
  date: Date | number;
  setIsNewYear: (value: boolean) => void;
}

const NewYearCountDown: FC<IExactScoreCountDownProps> = ({ date, setIsNewYear }) => {
  const handleComplete = () => {
    setIsNewYear(false);
  };

  return (
    <Countdown
      date={date}
      renderer={(props) => renderer(props)}
      onComplete={handleComplete}
    />
  );
};

export default NewYearCountDown;
