import { commonApi } from '@common/utils/baseQuery';
import { IBanners } from '@features/banners/api/bannersApi.type';

export const bannersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchBanners: builder.query<IBanners, void>({
      query: () => '/banner',
    }),
    
  }),
});

export const { useFetchBannersQuery } = bannersApi;
